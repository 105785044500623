$primary: #2da562;
$green: #2da562;

@import '~bootstrap/scss/bootstrap.scss';
@import './layout.scss';
@import './choices.scss';
@import './week-time-selector.scss';
@import './extra-config.scss';

$popover-max-width: 300px;

body .table {
  --bs-table-striped-bg: #eee6;
}

.nav-tabs .nav-link.active {
  color: #000;
  font-weight: bold;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  color: #fff;
}

.turbolinks-progress-bar {
  height: 4px;
  background-color: $primary;
}

// table
.table {
  .hover-visible {
    display: none;
  }

  tr:hover {
    .hover-visible {
      display: initial;
    }

    .hover-hidden {
      display: none;
    }
  }
}
