[data-controller='extra-config'] {
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
    min-height: 100px;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #96d2b1;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(45 165 98 / 25%);
    }
  }

  & > textarea,
  &::after {
    // border: 1px solid black;
    padding: 1rem 0.75rem;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace !important;
    grid-area: 1 / 1 / 2 / 2;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
