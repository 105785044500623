.form-floating {
  .choices[data-type='text'] {
    padding-top: 1.625rem;

    & + .form-label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
  }
}

.choices {
  margin-bottom: 0;

  &[data-type*='select-one'] {
    &::after {
      display: none;
    }

    .choices__button {
      margin-right: 8px;
    }
  }

  &[data-type='text'] {
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .choices__inner {
    padding: 0 !important;
    min-height: 0;
    background-color: #0000;
    border: 0;
  }

  .choices__placeholder {
    opacity: 1;
  }

  .choices__list--single {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
  }

  .choices__list--dropdown {
    left: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #00000026;
    border-radius: 0.25rem;

    .choices__input {
      padding: 0.5rem 1rem;
    }

    .choices__list {
      max-height: calc(100vh - 120px);
      padding: 0.5rem 0;
    }

    .choices__item {
      padding: 0.25rem 1rem;
      color: #212529;
    }

    .choices__item--selectable.is-highlighted {
      color: #1e2125;
      background-color: #e9ecef;
    }
  }

  .choices__input {
    background-color: #fff;
  }

  .choices__list--multiple {
    .choices__item {
      background-color: #999;
      border: none;

      .choices__button {
        margin-left: 0 !important;
        border: none !important;
      }
    }
  }
}
