body {
  min-height: 100vh;
  font-size: 14px;
  color: $dark;
  font-family: -apple-system, Helvetica Neue, Helvetica, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, SimSun, Segoe UI, Roboto, sans-serif;
  line-height: 1.5;
  word-break: break-word;
  background-color: #fbf7f0;
}

// Sidebar

$sidebar-width: 130px;

@include media-breakpoint-up(lg) {
  .sidebar {
    width: $sidebar-width;
  }

  .main {
    width: calc(100vw - #{$sidebar-width});
  }
}


.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    font-weight: 500;
    color: $dark;
  }

  .nav-link.active,
  .nav-link.active .bi {
    color: $primary;
  }

  .nav-link .bi {
    margin-right: 4px;
    color: $gray-600;
  }

  .sidebar .nav-link:hover .bi,
  .sidebar .nav-link.active .bi {
    color: inherit;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

// Navbar

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

// 小屏幕表格样式
[data-project-tag-selector-with-list-target="list"] {
  max-height: 1000px;
  font-size: 12px;
  overflow-x: auto;
}

@media screen and (max-height: 800px) {
  #compare-table-wrapper .table > :not(caption) > * > * {
    padding: 0.1em 0.5em;
  }

  [data-project-tag-selector-with-list-target="list"] {
    max-height: 680px;
  }
}
