.week-time-selector {
  user-select: none;

  .selector-scroll {
    overflow-y: auto;
  }

  .selector-item {
    min-width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 28px;
    border: 1px solid #eee;

    &.ds-selected {
      background-color: #2da56266;
    }
  }
}
